<template>
    <div class="webcastsRisk"></div>
</template>

<script>
export default {
    name: 'webcastsRisk',
    data() {
        return {
          socialData:[],
          moneyData:[]
        }
    },
    methods: {
        // 网播风险分析
        setWebcasts() {
            let option = {

                title: {
                    text: '项目数据',
                    textStyle: {
                        color: "#fff",
                        fontSize: 12
                    },
                    top: '4%',
                    left: '4%'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                legend: {
                    data: [
                        {name: '投入款物（万元）', icon: 'circle'},
                        {name: '社会组织参与数', icon: 'circle'},
                    ],
                    gridIndex: 3,
                    right: "5%",
                    top: "6%",
                    itemWidth: 7,
                    itemHeight: 7,
                    textStyle: {
                        color: '#00CCFF',
                        fontSize: 10
                    }
                },
                grid: {
                    top: '15%',
                    left: '3%',
                    right: '50%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {show: false},
                    axisTick: {show: false},
                    splitLine: {show: false},
                    axisLine: {show: false}
                },
                yAxis: {
                    type: 'category',
                    axisTick: {show: false},
                    splitLine: {show: false},
                    axisLine: {show: false},
                    axisLabel: {
                        color: '#fff',
                        backgroundColor: '#4A7AFF',
                        borderRadius: 3,
                        margin: 0,
                        padding: [1, 3, 1, 3]
                    },
                    inverse: true,
                    data: ["长沙市",'株洲市','湘潭市','衡阳市',"常德市",'益阳市','娄底市'],
                },
                series: [
                    // {
                    //     name: '活动场次',
                    //     type: 'bar',
                    //     stack: '总量',
                    //     barWidth: 8,
                    //     itemStyle: {
                    //         color: '#142AFE',
                    //         barBorderRadius: [0, 8, 8, 0]
                    //     },
                    //     label: {
                    //         normal: {
                    //             show: false,
                    //
                    //             position: 'insideRight'
                    //         }
                    //     },
                    //     data: [14,2,3,0,3,0,1,1,1,2]
                    // },
                    {
                        name: '社会组织参与数',
                        type: 'bar',
                        stack: '总量',
                        barWidth: 8,
                        itemStyle: {
                            color: '#1456FE',
                            barBorderRadius: [0, 8, 8, 0]
                        },
                        label: {
                            normal: {
                                show: false,
                                position: 'insideRight'
                            }
                        },
                        data: this.socialData
                    },
                    {
                        name: '投入款物（万元）',
                        type: 'bar',
                        barWidth: 8,
                        stack: '总量',
                        itemStyle: {
                            color: '#00CCFF',
                            barBorderRadius: [0, 8, 8, 0]
                        },
                        label: {
                            normal: {
                                show: false,
                                position: 'insideRight'
                            }
                        },
                        data:this.moneyData
                    },
                    // {
                    //     type: 'pie',
                    //     radius: ['20%', '21%'],
                    //     center: ['72%', '60%'],
                    //     data: [{value: '1'}],
                    //     label: {show: false},
                    //     itemStyle: {
                    //         color: '#7D7DA2'
                    //     }
                    // },
                    // {
                    //     type: 'pie',
                    //     radius: ['40%', '65%'],
                    //     roseType: 'area',
                    //     center: ['72%', '60%'],
                    //     avoidLabelOverlap: true,
                    //     startAngle: 180,
                    //     label: {
                    //         normal: {
                    //             show: true,
                    //             padding: [0, -30],
                    //             formatter: '{c} \n\n',
                    //             position: 'outside',
                    //             color: '#fff'
                    //         },
                    //         emphasis: {
                    //             show: true,
                    //             textStyle: {
                    //                 fontSize: '30',
                    //                 fontWeight: 'bold'
                    //             }
                    //         }
                    //     },
                    //     labelLine: {
                    //         show: true,
                    //         length2: 30,
                    //         lineStyle: {
                    //             //color:["#408BE8",'#FE405C',"#3E3E7F","#FFC740"]
                    //         },
                    //
                    //     },
                    //     itemStyle: {
                    //         color: function (params) {
                    //             // let corList = ["#142AFE", '#3FA0FF', "#00CCFF", "#1456FE"];
                    //             let corList = ["#00CCFF", "#1456FE"];
                    //             return corList[params.dataIndex]
                    //         }
                    //     },
                    //     data: [
                    //         // {value: 28, name: '活动场次'},
                    //         {value: 6, name: '社会组织参与数'},
                    //         {value: 42, name: '投入款物（万元）'},
                    //         // {value: 27, name: '覆盖学校数量'},
                    //     ]
                    // }
                ]
            };
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize();
            myChart.setOption(option);
        },
    },
    mounted() {
        this.setWebcasts()
    },
}
</script>

<style lang="less" scoped>
.webcastsRisk {
    height: 100%;
}
</style>
